

<template>
  <div class="reset-password">
    <h2>Reset Password</h2>
    <el-form class="user-form" :model="form" :rules="rules" ref="form" label-width="160px" label-suffix=":">
      <el-form-item label="New password" prop="password" required>
        <el-input type="password" maxlength="30" v-model="form.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Confirm password" prop="checkPass">
        <el-input type="password" maxlength="30" v-model="form.checkPass" autocomplete="off"></el-input>
      </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">OK</el-button>
    </el-form-item>
    </el-form>
  </div>
</template>
<script>
import md5 from 'js-md5'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input password'))
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input password'))
      } else if (value !== this.form.password) {
        callback(new Error('The two passwords are inconsistent'))
      } else {
        callback()
      }
    }
    return {
      form: {
        password: '',
        checkPass: '',
      },
      rules: {
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    submit () {
      this.$store.httpDispatch({})('resetPassword', {
        password: md5(this.form.password),
        key: this.$route.query.key
      }).then((res) => {
        this.$notify.success({ title: 'Password changed successfully', duration: 5000 })
        this.$router.push('/')
      }).catch((error) => {
        console.log(error)
        if (error.data) {
          if (error.data.code === 4010001) {
            this.$notify({
              title: 'Password Error',
              type: 'warning'
            })
          }
        }
      })
    }
  }
}
</script>
<style>
.reset-password {
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 80px;
  padding: 50px 50px 50px;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  border: 1px solid #DFDFDF;
}
.reset-password .el-button {
  width: 100%
}
</style>
